import React, {Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import routes, {adminPrefix} from '../../routes';
import Loader from "./Loader";
import AuthLayout from "./Auth";
import Page404 from "../../views/auth/Page404";
import {hasRole} from "../auth/PrivateRoute";
import {useSelector} from "react-redux";

const TheContent = () => {

    const user = useSelector((state) => state.auth.user);

    return (
        <Suspense fallback={<Loader/>}>
            <Switch>
                {routes.adminRoutes.map((route, idx) => {
                    if (route.roles && hasRole(user, route.roles)) {
                        if (route.children) {
                            return route.children.map((child, idx) => {
                                if (child.roles && hasRole(user, child.roles)) {
                                    return (
                                        <Route
                                            key={idx}
                                            path={child.path}
                                            exact={child.exact}
                                            name={child.name}
                                            render={props => (
                                                <child.component {...props} />
                                            )}/>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        } else {
                            return (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                        <route.component {...props} />
                                    )}/>
                            )
                        }
                    }
                    return true;
                })}
                <Redirect exact from={adminPrefix} to={adminPrefix + `/dashboard`}/>
                <Route>
                    <AuthLayout>
                        <Page404 />
                    </AuthLayout>
                </Route>
            </Switch>
        </Suspense>
    )
}

export default React.memo(TheContent);
import * as types from "../constants";

const initialState = {
    page: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.UNIT_PAGE_SET:
            return {
                ...state,
                page: action.payload
            };

        default:
            return state;
    }
}

import * as types from "../constants";

const initialState = {
    user: false,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isRefreshingToken: false,
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {

        case types.AUTH_SIGN_IN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                user: actions.email
            };

        case types.AUTH_SIGN_IN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isLoggedIn: true,
                user: {
                    token: actions.token,
                    ...actions.user
                }
            };

        case types.AUTH_SIGN_IN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                user: false
            };

        case types.AUTH_REFRESH_REQUEST:
            return {
                ...state,
                isRefreshingToken: true
            };

        case types.AUTH_REFRESH_SUCCESS:
            return {
                ...state,
                isRefreshingToken: false,
                isLoggedIn: true,
                user: {
                    token: actions.token,
                    ...actions.user
                }
            };

        case types.AUTH_REFRESH_FAILURE:
            return {
                ...state,
                isRefreshingToken: false,
                isLoggedIn: false,
                user: false
            };

        case types.AUTH_SIGN_OUT_REQUEST:
            return {
                ...state,
                isLoggingOut: true
            };

        case types.AUTH_SIGN_OUT_SUCCESS:
            return {
                ...state,
                isLoggingOut: false,
                isLoggedIn: false,
                user: false
            };

        case types.AUTH_SIGN_OUT_FAILURE:
            return {
                ...state,
                isLoggingOut: false
            };

        default:
            return state;
    }
}

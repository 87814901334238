import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Popover as MuiPopover,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import { Bell } from "react-feather";
import { Chat, Close, Description, EventNote, Notifications } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { adminPrefix } from "../../routes";
import NotificationService from "../../services/NotificationService";
import notificationAudio from "../../assets/sounds/noti-3.mp3";
import { formatDatePretty } from "./helpers/Dates";
import { useTheme } from "@mui/styles";
import _ from "lodash";

const Popover = styled(MuiPopover)`
    .MuiPaper-root {
        width: 100%;
        max-width: 400px;
        ${(props) => props.theme.shadows[1]};
        border: 1px solid ${(props) => props.theme.palette.divider};
    }
`;

const Indicator = styled(Badge)`
    .MuiBadge-badge {
        background: ${(props) => props.theme.header.indicator.background};
        color: ${(props) => props.theme.palette.common.white};
    }
`;

const NotificationHeader = styled(Box)`
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const NotificationsDropdown = () => {
    const user = useSelector(state => state.auth.user);
    const themeState = useSelector(state => state.theme);
    const theme = useTheme();
    const history = useHistory();
    const notificationIntervalCheck = 30000;
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [previousNotifications, setPreviousNotifications] = useState([]);
    const [groupedNotifications, setGroupedNotifications] = useState([]);
    const [unviewedNotifications, setUnviewedNotifications] = useState(0);
    const [unviewedPreviousNotifications, setUnviewedPreviousNotifications] = useState(0);

    /**
     * Get notifications from server
     * @return {Promise<void>}
     */
    const getNotifications = useCallback(async () => {
        if (user) {
            const notifications = await NotificationService.find({ "user.id": user.id });
            if (notifications?.data) {
                setNotifications(notifications.data);
            }
        }
    }, [user]);

    /**
     * Initial useEffect on load
     */
    useEffect(() => {
        let active = true;

        // Set initial notifications
        (async () => {
            await getNotifications();
        })();

        // Start noti check interval
        const interval = setInterval(() => {
            (async () => {
                if (active) await getNotifications();
            })();
        }, notificationIntervalCheck);

        return () => {
            active = false;
            clearInterval(interval);
        };
    }, [getNotifications]);

    /**
     * useEffect any time notifications array changes
     */
    useEffect(() => {
        // If there's unviewed messages play sound
        let unviewed = notifications.filter(o => o.viewed === false);
        if (unviewed.length) {
            setUnviewedNotifications(unviewed.length);
            const sound = new Audio(notificationAudio);
            sound.play().catch(() => {
            });
        }
    }, [notifications]);

    /**
     * useEffect groups notifications anytime it changes and changes display
     */
    useEffect(() => {
        let n = _.cloneDeep(notifications);
        let updated = [];

        const grouped = _.groupBy(n, o => o.description);
        _.forEach(grouped, (group, key) => {
            // Get length of grouped notifications
            let l = group.length;

            // Gather all ids for deletion
            let ids = [];
            _.forEach([...group], notification => {
                ids.push(notification.id);
            })

            // Get most recent notification to modify
            let first = group.shift();

            // Push ids to id field
            first.id = ids;

            // Modify title
            let title = `${l > 1 ? l : ''} ${first.title}${(l > 1) ? 's' : ''}`;
            first.title = title;

            // Push updated notification to list
            updated.push(first);
        })

        setGroupedNotifications(updated);

    }, [notifications]);


    /**
     * Handle notifications menu open
     * @return {Promise<void>}
     */
    const handleOpen = async () => {
        setOpen(true);

        // If unviewed notifications, update all to viewed
        let unviewed = notifications.filter(o => o.viewed === false);
        if (unviewed.length) {
            const ids = notifications.map(o => o.id);
            const data = { ids: ids, viewed: true };
            const update = await NotificationService.updateMany(data);

            // If successful remove badge
            if (update.data && update.data.ok) {
                setUnviewedNotifications(0);
            }
        }
    };

    /**
     * Handle notifications menu close
     * @return {Promise<void>}
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Handle notifications button click
     * @param id
     * @param url
     * @return {Promise<void>}
     */
    const handleClick = async (ids, url) => {
        // Remove notifications when clicked as it can be considered used
        await NotificationService.removeAll(ids);

        // Update notifications?
        await getNotifications();

        // Send to url
        if (url) {
            history.push(adminPrefix + url);
            handleClose();
        }
    };

    /**
     * Handle clearing a single notification
     * @param id
     * @return {Promise<void>}
     */
    const clearNotification = async (ids) => {
        await NotificationService.removeAll(ids);
        await getNotifications();
    };

    /**
     * Handle clearing all notifications
     * @return {Promise<void>}
     */
    const clearNotifications = async () => {
        const ids = notifications.map(o => o.id);
        await NotificationService.removeAll(ids);
        await getNotifications();
    };

    return (
        <React.Fragment>
            <Tooltip title="Notifications">
                <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
                    <Indicator badgeContent={unviewedNotifications}>
                        <Bell />
                    </Indicator>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <NotificationHeader p={2}>
                    <Typography variant="subtitle1" color="textPrimary">
                        {notifications.length} New Notifications
                    </Typography>
                </NotificationHeader>
                <React.Fragment>
                    <List disablePadding>
                        {groupedNotifications.map(o => {
                            let icon;
                            switch (o.type) {
                                case "tor":
                                    icon = <EventNote />;
                                    break;
                                case "message":
                                    icon = <Chat />;
                                    break;
                                case "document":
                                    icon = <Description />;
                                    break;
                                default:
                                    icon = <Notifications />;
                            }
                            return (
                                <ListItem divider button onClick={() => handleClick(o.id, o.url)} key={o.id}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ background: o.viewed ? '#c0c0c0' : theme.palette.primary.main }}>
                                            <SvgIcon fontSize="small"
                                                     color={themeState.currentTheme === "DARK" ? "action" : ""}>
                                                {icon}
                                            </SvgIcon>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                    >
                                        <Typography variant="subtitle2" color="textPrimary"
                                                    sx={{ marginBottom: 1, fontWeight: "bold" }}>{o.title}</Typography>
                                        <Typography variant="caption"
                                                    component="div">{formatDatePretty(o.createdAt)}</Typography>
                                        <Typography variant="body3">{o.description}</Typography>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => clearNotification(o.id)} edge="end"
                                                    aria-label="delete" size="large">
                                            <Close fontSize="small" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}

                    </List>
                    {notifications.length > 0 &&
                        <Box p={1} display="flex" justifyContent="center">
                            <Button size="small" onClick={clearNotifications}>
                                Clear all notifications
                            </Button>
                        </Box>}
                </React.Fragment>
            </Popover>
        </React.Fragment>
    );
};

export default NotificationsDropdown;

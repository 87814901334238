import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";

import { IconButton as MuiIconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import { signOut } from "../../redux/actions/AuthActions";
import { Link } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const dispatch = useDispatch();

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleSignOut = async () => {
        await dispatch(signOut());
    };

    return (
        <React.Fragment>
            <Tooltip title="Account">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                    size="large">
                    <AccountCircle />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem onClick={closeMenu} component={Link} to="/admin/account">Account</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default UserDropdown;

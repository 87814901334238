import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENV === "dev" ? 1.0 : 0.25,
    enabled: process.env.REACT_APP_ENV !== "dev"
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

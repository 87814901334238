import React from 'react';
import Page500 from "../../views/auth/Page500";

class ErrorBoundary500 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <Page500/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary500;
import axios from "axios";

const find = async (find = null, select = null) => {
    return await axios.get('/users', { params: { find: { ...find }, select: select } } );
};

const findById = async (id) => {
    return await axios.get('/users/' + id);
};

const create = async (data) => {
    return await axios.post('/users', data);
};

const update = async (id, data) => {
    return await axios.put('/users/' + id, data);
};

const remove = async (id) => {
    return await axios.delete('/users/' + id);
};

const support = async (data) => {
    return await axios.post('/users/support', data);
};

const findBirthdays = async () => {
    return await axios.get('/users/birthdays');
};

const UserService = {
    find,
    findById,
    create,
    update,
    remove,
    support,
    findBirthdays
}

export default UserService;
import moment from 'moment-timezone';
const tz = moment.tz.guess();

export const formatDateInGrid = (item) => {
    return item.value ? moment(item.value).tz(tz).format('MM/DD/YYYY HH:mm:ss z') : null;
}

export const formatDate24Hour = (date) => {
    return date ? moment(date).tz(tz).format('MM/DD/YYYY HH:mm:ss z') : null;
}

export const formatDate = (date) => {
    return date ? moment(date).tz(tz).format('MM/DD/YYYY') : null;
}

export const formatDateContract = (date, timeframe = 'BY') => {
    return moment(date).tz(tz).format(`MM/DD/YYYY [${timeframe}] HH:mm z`);
}

export const formatDateCustomer = (date) => {
    const d = new Date(date);
    return `${d.toLocaleTimeString('en-US', {
        timeZoneName: 'short',
        hour: '2-digit',
        minute: '2-digit'
    })} ${d.toLocaleDateString('en-US', {
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric'
    })}`;
}

export const formatDatePretty = (date) => {
    return date ? moment(date).tz(tz).format('llll') : null;
}
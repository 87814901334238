import React from "react";
import {createGlobalStyle} from "styled-components/macro";
import {
    CssBaseline
} from "@mui/material";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const DefaultLayout = ({ children }) => {
    return (
        <>
            <CssBaseline/>
            <GlobalStyle/>
            {children}
        </>
    )
}

export default DefaultLayout;
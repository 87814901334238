import { combineReducers } from "redux";

import theme from "./ThemeReducer";
import auth from "./AuthReducer";
import carriers from "./CarrierReducer";
import units from "./UnitReducer";

export const rootReducer = combineReducers({
  theme,
  auth,
  carriers,
  units
});

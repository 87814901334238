import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "./Sidebar";
import Header from "./AppBar";
import Content from "./Content";
import Footer from "./Footer";
import routes from "../../routes";
import ErrorBoundary500 from "../errors/ErrorBoundary500";

import { spacing } from "@mui/system";
import { CssBaseline, Hidden, Paper as MuiPaper } from "@mui/material";

import useNotifier, { useWidth } from "../../utils/hooks";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .rp-breadcrumb-bar {
    background: ${(props) => props.theme.header.breadcrumbs.background};
    border-top: 1px solid ${(props) => props.theme.header.breadcrumbs.border};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  /*.MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }*/
`;

const Main = () => {
    const width = useWidth();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Use Redux snackbar
    useNotifier();

    return (
      <Root>
          <CssBaseline />
          <GlobalStyle />
          <Drawer>
              <Hidden mdUp implementation="js">
                  <Sidebar
                      routes={routes.adminRoutes}
                      PaperProps={{ style: { width: drawerWidth } }}
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                  />
              </Hidden>
              <Hidden mdDown implementation="css">
                  <Sidebar
                      routes={routes.adminRoutes}
                      PaperProps={{ style: { width: drawerWidth } }}
                  />
              </Hidden>
          </Drawer>
          <AppContent>
              <Header width={width} onDrawerToggle={handleDrawerToggle} />
              <MainContent p={("lg" === width) ? 12 : 5}>
                  <ErrorBoundary500>
                      <Content />
                  </ErrorBoundary500>
              </MainContent>
              <Footer />
          </AppContent>
      </Root>
    );
};

export default Main;

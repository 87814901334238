import axios from "axios";

export const signIn = async (credentials) => {
  return await new Promise((resolve, reject) => {
    axios
      .post("/login", {...credentials})
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const refresh = async () => {
    return await new Promise((resolve, reject) => {
        axios
            .post("/refresh")
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                }
                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const signOut = () => {
    return new Promise((resolve, reject) => {
        axios
            .post("/logout")
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                }
                reject();
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/*export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}*/

import React, { useEffect, useContext, createContext } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {refreshToken} from "../../redux/actions/AuthActions";

const authContext = createContext(null);

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {

    const dispatch = useDispatch();
    const history = useHistory();
    let user = useSelector(state => state.auth.user);
    let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    let isRefreshingToken = useSelector(state => state.auth.isRefreshingToken);
    let isLoggingOut = useSelector(state => state.auth.isLoggingOut);

    useEffect(() => {

        if (isLoggingOut) {
            history.push('/admin/login');
        }

        if (!isLoggedIn && !user && !isRefreshingToken) {
            (async () => {
                await dispatch(
                    refreshToken()
                )
                .then()
                .catch(() => {
                    history.push('/admin/login');
                });
            })();
        }

        const storageLogout = (event) => {
            if (event.key === 'rp-logout') {
                history.push('/admin/login');
            }
        }

        window.addEventListener('storage', storageLogout);

        return () => {
            window.removeEventListener('storage', storageLogout);
        };


    }, [user, isLoggedIn, isLoggingOut, isRefreshingToken, dispatch, history]);

    return {
        user,
        isLoggedIn
    };
}
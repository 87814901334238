import * as types from "../constants";
import {
    signIn as authSignIn,
    signOut as authLogout,
    refresh as authRefresh,
} from "../../services/AuthService";

export const signIn = (credentials) => {
    return async (dispatch) => {

        let {email} = credentials;
        dispatch(
            request({email})
        );

        return authSignIn(credentials)
            .then((response) => {
                let user = JSON.parse(atob(response.token.split('.')[1]));
                dispatch(
                    success(response.token, user)
                );
            })
            .catch((error) => {
                dispatch(
                    failure(error)
                );
                throw error;
            });

        function request(payload) {
            return {type: types.AUTH_SIGN_IN_REQUEST, email}
        }

        function success(token, user) {
            return {type: types.AUTH_SIGN_IN_SUCCESS, token, user}
        }

        function failure(error) {
            return {type: types.AUTH_SIGN_IN_FAILURE, error}
        }

    };
}

/*
 * Refresh Token
 */
export const refreshToken = () => {
    return async (dispatch) => {

        dispatch(
            refreshRequest()
        );
        return await authRefresh()
            .then((response) => {
                let user = JSON.parse(atob(response.token.split('.')[1]));
                dispatch(
                    refreshSuccess(response.token, user)
                );
            }).catch((error) => {
                dispatch(
                    refreshFailed()
                );
                return Promise.reject(error);
            });

        function refreshRequest() {
            return {type: types.AUTH_REFRESH_REQUEST}
        }

        function refreshSuccess(token, user) {
            dispatch({ type: types.THEME_SET, payload: user.preferences.theme });
            return {type: types.AUTH_REFRESH_SUCCESS, token, user}
        }

        function refreshFailed() {
            return {type: types.AUTH_REFRESH_FAILURE}
        }

    }
}

/*
 * Logout
 */
export const signOut = () => {
    return async (dispatch) => {

        dispatch(
            logoutRequest()
        );
        return authLogout()
            .then(() => {
                dispatch(
                    logoutSuccess()
                );
                window.localStorage.setItem('rp-logout', Date.now());
            }).catch((error) => {
                dispatch(
                    logoutFailed()
                );
                throw error;
            });

        function logoutRequest() {
            return {type: types.AUTH_SIGN_OUT_REQUEST}
        }

        function logoutSuccess() {
            return {type: types.AUTH_SIGN_OUT_SUCCESS}
        }

        function logoutFailed() {
            return {type: types.AUTH_SIGN_OUT_FAILURE}
        }
    }
}

/*export function resetPassword(credentials) {
    return async (dispatch) => {
        dispatch({type: types.AUTH_RESET_PASSWORD_REQUEST});

        return authResetPassword(credentials)
            .then((response) => {
                dispatch({
                    type: types.AUTH_RESET_PASSWORD_SUCCESS,
                    email: response.email,
                });
            })
            .catch((error) => {
                dispatch({type: types.AUTH_RESET_PASSWORD_FAILURE});
                throw error;
            });
    };
}*/

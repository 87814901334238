import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IconButton, Tooltip} from "@mui/material";
import {Sun, Moon} from "react-feather";
import {THEMES} from "../../redux/constants";
import {setTheme} from "../../redux/actions/ThemeActions";

const ChangeTheme = () => {

    const dispatch = useDispatch();
    const currentTheme = useSelector((state) => state.theme.currentTheme);

    const handleThemeChange = (theme) => {
        dispatch(setTheme(theme));
    }

    return (
        <Tooltip title="Change Theme">
            <IconButton
                aria-label="Change Theme"
                aria-haspopup="true"
                color="inherit"
                onClick={() => handleThemeChange(currentTheme === 'DEFAULT' ? THEMES.DARK : THEMES.DEFAULT)}
                size="large">
                {currentTheme === 'DEFAULT' ? <Moon/> : <Sun/>}
            </IconButton>
        </Tooltip>
    );
}

export default ChangeTheme;
import React from "react";
import styled, {withTheme} from "styled-components/macro";
import {
    AppBar as MuiAppBar,
    Breadcrumbs, Grid as MuiGrid,
    Hidden,
    IconButton as MuiIconButton,
    Link,
    Toolbar,
    Typography
} from "@mui/material";
import {spacing} from "@mui/system";
import {Menu as MenuIcon} from "@mui/icons-material";
import UserDropdown from "./UserDropdown";
import {NavLink, useLocation} from "react-router-dom";
import ChangeTheme from "./ChangeTheme";
import routes, {adminPrefix} from "../../routes";
import {useSelector} from "react-redux";
import NotificationsDropdown from "./NotificationsDropdown";
import { useWidth } from "../../utils/hooks";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  .rp-appbar-left {
    color: ${(props) => props.theme.header.colorDark};
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Grid = styled(MuiGrid)(spacing);
const MuiToolbar = styled(Toolbar)(spacing);

const AppBarComponent = ({onDrawerToggle}) => {
    const width = useWidth();

    const user = useSelector((state) => state.auth.user);
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    // Remove ID from paths
    if (['update','view'].some(i => pathnames.includes(i))) pathnames.pop();

    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Hidden mdUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={onDrawerToggle}
                                    size="large">
                                    <MenuIcon/>
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs className="rp-appbar-left">Welcome, {user.firstName} {user.lastName}.</Grid>
                        <Grid item>
                            <NotificationsDropdown/>
                            <ChangeTheme/>
                            <UserDropdown/>
                        </Grid>
                    </Grid>
                </Toolbar>
                <MuiToolbar className="rp-breadcrumb-bar" px={("lg" === width) ? 12 : 5} variant="dense">
                    <Grid container pl={4}>
                        <Grid item>
                            <Breadcrumbs aria-label="Breadcrumb">

                                {pathnames.map((value, index) => {
                                    const last = index === pathnames.length - 1;
                                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                                    const isHome = (to === adminPrefix);
                                    let route, noComponent;

                                    const allRoutes = routes.adminRoutes.reduce((routes, route) => {
                                        if (route.children) {
                                            route.children.map(child => {
                                                routes.push(child);
                                                return true;
                                            })
                                        }
                                        routes.push(route);
                                        return routes;
                                    }, []);

                                    allRoutes.forEach((v) => {
                                        let path = v.path;
                                        if (['update','view'].some(i => path.includes(i))) {
                                            path = path.substring(0, path.lastIndexOf('/'));
                                        }
                                        if (path === to) {
                                            route = v.breadcrumb ? v.breadcrumb : v.name;
                                            noComponent = isHome || !v.component;
                                        }
                                    });
                                    return (last || noComponent) ? (
                                        <Typography color="textPrimary" key={to}>
                                            {route}
                                        </Typography>
                                    ) : (
                                        <Link component={NavLink} exact={isHome} to={to} key={`nav_${index}`}>
                                            {isHome ? 'Home' : route}
                                        </Link>
                                    );
                                })}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </MuiToolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default withTheme(AppBarComponent);

import React from "react";
import {Redirect, Route} from "react-router-dom";
import MainLayout from "../layout/Main";
import {useAuth} from "./Auth";
import {adminPrefix} from "../../routes";

export const hasRole = (user, roles) => roles.some(role => user.roles.includes(role));

const PrivateRoute = () => {

    const auth = useAuth();
    if (!auth.isLoggedIn || !auth.user) {
        return null;
    }

    return (
        <Route render={({ location }) =>
            auth.isLoggedIn && auth.user ? (
                <MainLayout/>
            ) : (
                <Redirect
                    to={{
                        pathname: adminPrefix + '/login',
                        state: { from: location }
                    }}
                />
            )
        }
        />
    )
}

export default PrivateRoute;

import React from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components/macro";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: sticky;
  left: auto;
  bottom: 0;
  right: 0;
  z-index: 500;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden>
          <Grid container item xs={6}>
            <List>
              <ListItem button={true} component={Link} to="/admin/support">
                <ListItemText primary="Support" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={6} justifyContent="flex-end">
          <List>
            <ListItem button={false}>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - RP Expediting`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK"
};
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';


// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT_REQUEST = "AUTH_SIGN_OUT_REQUEST";
export const AUTH_SIGN_OUT_SUCCESS = "AUTH_SIGN_OUT_SUCCESS";
export const AUTH_SIGN_OUT_FAILURE = "AUTH_SIGN_OUT_FAILURE";
export const AUTH_REFRESH_REQUEST = "AUTH_REFRESH_REQUEST";
export const AUTH_REFRESH_SUCCESS = "AUTH_REFRESH_SUCCESS";
export const AUTH_REFRESH_FAILURE = "AUTH_REFRESH_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Carriers
export const CARRIER_PAGE_SET = "CARRIER_PAGE_SET";

// Units
export const UNIT_PAGE_SET = "UNIT_PAGE_SET";
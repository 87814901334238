import React, {Suspense} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import routes, {adminPrefix} from "./index";
import Loader from "../components/layout/Loader";

import DefaultLayout from "../components/layout/Default";
import AuthLayout from "../components/layout/Auth";
import Page404 from "../views/auth/Page404";
import PrivateRoute from "../components/auth/PrivateRoute";
import {ProvideAuth} from "../components/auth/Auth";

const childRoutes = (Layout, routes) =>
    routes.map(({component: Component, roles, children, path}, index) => {

        return children ? (
            children.map((element, index) => {

                return (
                    <Route
                        key={index}
                        path={element.path}
                        exact
                        render={(props) => (
                            <Layout>
                                <element.component {...props} />
                            </Layout>
                        )}
                    />
                );
            })
        ) : Component ? (
            <Route
                key={index}
                path={path}
                exact
                render={(props) => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        ) : null;
    });

const Routes = () => (
    <Router>
        <Suspense fallback={<Loader/>}>
            <Switch>
                {childRoutes(DefaultLayout, routes.publicRoutes)}
                {childRoutes(AuthLayout, routes.authRoutes)}
                <Route path={adminPrefix}>
                    <ProvideAuth>
                        <PrivateRoute/>
                    </ProvideAuth>
                </Route>
                <Route exact path="/">
                    <Redirect to={'/track'}/>
                </Route>
                <Route>
                    <AuthLayout>
                        <Page404 />
                    </AuthLayout>
                </Route>
            </Switch>
        </Suspense>
    </Router>
);

export default Routes;

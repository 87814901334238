import * as types from "../constants";
import { THEMES } from "../constants";

const initialState = {
    currentTheme: THEMES.DEFAULT,
    snacks: [],
    notifications: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.THEME_SET:
            return {
                ...state,
                currentTheme: action.payload
            };

        case types.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };

        case types.ENQUEUE_SNACKBAR:
            return {
                ...state,
                snacks: [
                    ...state.snacks,
                    {
                        key: action.key,
                        ...action.snack
                    }
                ]
            };

        case types.CLOSE_SNACKBAR:
            return {
                ...state,
                snacks: state.snacks.map(snack => (
                    (action.dismissAll || snack.key === action.key)
                        ? { ...snack, dismissed: true }
                        : { ...snack }
                ))
            };

        case types.REMOVE_SNACKBAR:
            return {
                ...state,
                snacks: state.snacks.filter(
                    snack => snack.key !== action.key
                )
            };

        default:
            return state;
    }
}
